<template>
	<tr>
		<td class="d-flex flex-nowrap align-content-center">
			<v-avatar class="mr-2 flex-shrink-1" size="48">
				<v-img alt="Avatar" :src="item.imgURL" :aspect-ratio="1 / 1" />
			</v-avatar>
			<div class="d-flex justify-space-between flex-grow-1">
				<router-link class="text-decoration-none red--text my-auto" :to="{ name: 'User', params: { id: item.id } }" target="_blank">
					{{ item.name.full }}
				</router-link>
			</div>
		</td>
		<td class="text-center">
			<v-chip
				small
				:loading="loadingDocument"
				:disabled="loadingDocument"
				:ripple="false"
				:color="item.documents.cv.uploaded ? 'success' : 'error'"
				class="mx-1"
				@click="item.documents.cv.uploaded ? callDownloadDocument(item.documents.cv.id, item.id) : callNoDocument()"
			>
				<v-icon left>mdi-cloud-download</v-icon>
				{{ $t('documents.cv') }}
			</v-chip>
			<v-chip
				small
				:loading="loadingDocument"
				:disabled="loadingDocument"
				:ripple="false"
				:color="item.documents.motivation.uploaded ? 'success' : 'error'"
				class="mx-1"
				@click="item.documents.motivation.uploaded ? callDownloadDocument(item.documents.motivation.id, item.id) : callNoDocument()"
			>
				<v-icon left>mdi-cloud-download</v-icon>
				{{ $t('documents.motivationLetter') }}
			</v-chip>
			<v-chip
				small
				:loading="loadingDocument"
				:disabled="loadingDocument"
				:ripple="false"
				:color="item.documents.records.uploaded ? 'success' : 'error'"
				class="mx-1"
				@click="item.documents.records.uploaded ? callDownloadDocument(item.documents.records.id, item.id) : callNoDocument()"
			>
				<v-icon left>mdi-cloud-download</v-icon>
				{{ $t('documents.records') }}
			</v-chip>
		</td>
		<td class="text-center">
			<v-btn
				color="success darken-3"
				small
				rounded
				class="mx-1"
				:disabled="item.applied"
				:loading="loadingIncluding"
				@click="callIncludeUserInOffer(item.id, offer.id)"
			>
				{{ item.applied ? $t('settings.added') : $t('settings.add') }}
			</v-btn>
		</td>
	</tr>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'OfferTalentPoolUserItemDesktop',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		},
		item: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			loadingIncluding: false
		}
	},
	computed: {
		...mapGetters({
			offer: 'offers/offer'
		})
	},
	methods: {
		callDownloadDocument(documentID, userID) {
			this.loadingDocument = true
			this.downloadDocumentUser({ documentID, userID }).then(() => {
				this.loadingDocument = false
			})
		},
		callNoDocument() {
			this.noDocument()
		},
		callIncludeUserInOffer(userID, offerID) {
			this.loadingIncluding = true
			this.includeUserInOffer({ userID, offerID })
				.then(({ success }) => {
					if (success) this.item.applied = true
				})
				.then(() => {
					this.loadingIncluding = false
				})
		},
		...mapActions('users', ['includeUserInOffer']),
		...mapActions('documents', ['downloadDocumentUser', 'noDocument'])
	}
}
</script>
